var popup = $('.js-popup');
var popupclose = $('.js-close-popup');
$('html').css('position', 'static');
if ( popup.length ) {
  $('html').css( {
    'position': 'fixed',
    'width': '100%'
  } );
}

popupclose.on('click', function(event) {
  event.preventDefault();
  $('html').css('position', 'static');

  popup.hide();
});

popup.on('click', function (event) {
  $('html').css('position', 'static');

  popup.hide();

});
