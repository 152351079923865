(function ($) {
    $('.main-slider').slick({
        arrows: true,
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        pauseOnHover: false,
        nextArrow: '.next-slide',
        prevArrow: '.prev-slide'
    });

    $('.gallery').slick({
        arrows: true,
        dots: false,
        fade: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 1000,
        pauseOnHover: false
    });


     $('.scrolldown').on('click',function(e){
       e.preventDefault();
       var myval = $("#mainpage").offset().top - 80;
        $("html, body").animate({scrollTop: myval + "px" });
        return false;
    });

})(jQuery);
