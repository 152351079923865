(function($) {

    $(".block").on("click", function(e) {
        var currentblock = $(this);
        if (currentblock.hasClass("open")) {
            $(".block").removeClass("open");
            $(".block").removeClass("closed");
        } else {
            $(".block").each(function() {
                if (currentblock.find(".block__title").html() == $(this).find(".block__title").html()) {
                    $(this).addClass("open");
                } else {
                    $(this).addClass("closed");
                }
            });
        }
    });
})(jQuery);
