(function ($) {
      $("input, textarea").each(function(){
        if ($(this).is(':checkbox')) {
        }else{
             if ($(this).val()){
                 $(this).addClass('used');
             }
        }
    });

    $('input').change(function () {
        if ($(this).is(':checkbox')) {
        } else {
            if ($(this).val())
                $(this).addClass('used');
            else
                $(this).removeClass('used');
        }
    });

    $('textarea').change(function () {
        if ($(this).val())
            $(this).addClass('used');
        else
            $(this).removeClass('used');
    });


   //Handle the contact form
        var contactform = $('#main-contact-form');
        var target = Routing.generate('site_frontend_ajax_call');
        contactform.submit(function(e) {
                e.preventDefault();
                var form_status = $('<div class="form_status"></div>');
                $.ajax({
                    type: contactform.attr('method'),
                    url: target,
                    beforeSend: function(){
				contactform.prepend( form_status.html('<p><i class="fa fa-spinner fa-spin"></i> Email is sending...</p>').fadeIn() );
			},
                    data: {
                        entity: 'contact',
                        form: contactform.serializeArray()
                    },
                    success: function(data) {
                        if(data.status == 'ok'){
                            contactform.find("input[type=text], textarea").val("");
                            form_status.html('<p class="text-success">' + data.message + '</p>').delay(5000).fadeOut();
                        }
                        else {
                            form_status.html('<p class="text-error">' + data.message + '</p>').delay(5000).fadeOut();
                        }
                    }
                });
        });

})(jQuery);
